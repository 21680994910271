import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import $ from "jquery";
import Multiselect from "@vueform/multiselect";
import Vue3Toasity from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import "nprogress/nprogress.css";

import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

createApp(App)
  .use(store)
  .use(LoadingPlugin, { loader: "dots", color: "#355b7c" })
  .use(VueAxios, axios)
  .use($)
  .use(VueTelInput)

  .component("@vueform/multiselect", Multiselect)
  .use(router)

  .use(Vue3Toasity)

  .mount("#app");
