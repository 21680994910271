<template>
  <nav v-if="shouldShowNavbar" class="navbar navbar-expand-lg bg-light shadow-sm navbar fixed-top">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/logo.png" alt="logo" class="img-logo" />
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item px-1">
            <router-link class="nav-link fs" to="/" @click="closeMenu">Home</router-link>
          </li>

          <li class="nav-item px-1">
            <a class="nav-link fs" href="https://foodclubasia.com/" target="_blank" @click="closeMenu">Main Site</a>
          </li>

          <li class="nav-item px-1">
            <a class="nav-link fs" href="" target="_blank" @click="closeMenu">Category </a>
          </li>

          <li class="nav-item px-1">
            <a class="nav-link fs" href="" target="_blank" @click="closeMenu">Topics </a>
          </li>

          <!-- Restaurant -->
          <li class="nav-item px-1">
            <div class="dropdown">
              <a class="dropdown-toggle nav-link fs" href="#" id="dropdownResLink" data-bs-toggle="dropdown"
                aria-expanded="false">Restaurants </a>

              <ul class="dropdown-menu" ariabelledby="dropdownResLink">
                <li>
                  <a class="nav-link" href="https://app.foodclubasia.com/register-restaurant.php" target="_blank"
                    @click="closeMenu">Register</a>
                </li>
                <li>
                  <a class="nav-link" href="https://app.foodclubasia.com/login.php?type=Restaurant" target="_blank"
                    @click="closeMenu">Login</a>
                </li>
                <li>
                  <router-link class="nav-link" to="/restaurant-directory" @click="closeMenu">Directory</router-link>
                </li>
              </ul>
            </div>
          </li>

          <!-- Insiders -->
          <li class="nav-item px-1">
            <div class="dropdown">
              <a class="dropdown-toggle nav-link fs" href="#" id="dropdownInsLink" data-bs-toggle="dropdown"
                aria-expanded="false">Insiders </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownInsLink">
                <li>
                  <a class="nav-link" href="https://app.foodclubasia.com/register-insider.php" target="_blank"
                    @click="closeMenu">Register</a>
                </li>
                <li>
                  <a class="nav-link" href="https://app.foodclubasia.com/login.php?type=Insider" target="_blank"
                    @click="closeMenu">Login</a>
                </li>
                <li>
                  <router-link class="nav-link" to="/insider-profile-directory"
                    @click="closeMenu">Directory</router-link>
                </li>
              </ul>
            </div>
          </li>

          <!-- Vendors -->
          <li class="nav-item px-1">
            <div class="dropdown">
              <a class="dropdown-toggle nav-link fs" href="#" id="dropdownVenLink" data-bs-toggle="dropdown"
                aria-expanded="false" @click="closeMenu">Vendors </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownVenLink">
                <li>
                  <a class="nav-link" href="https://app.foodclubasia.com/register-vendor.php" target="_blank"
                    @click="closeMenu">Register</a>
                </li>
                <li>
                  <a class="nav-link" href="https://app.foodclubasia.com/login.php?type=Vendor" target="_blank"
                    @click="closeMenu">Login</a>
                </li>
                <li>
                  <router-link class="nav-link" to="/vendor-directory" @click="closeMenu">Directory</router-link>
                </li>
              </ul>
            </div>
          </li>

          <!-- Foodie -->
          <li class="nav-item px-1">
            <div class="dropdown">
              <a class="dropdown-toggle nav-link fs" href="#" id="dropdownResLink" data-bs-toggle="dropdown"
                aria-expanded="false">Foodie </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownResLink">
                <li>
                  <a class="nav-link" href="https://app.foodclubasia.com/register-foodie.php" target="_blank"
                    @click="closeMenu">Register</a>
                </li>
                <li>
                  <a class="nav-link" href="https://app.foodclubasia.com/login.php?type=Foodie" target="_blank"
                    @click="closeMenu">Login</a>
                </li>
                <li>
                  <router-link class="nav-link" to="/" @click="closeMenu">Directory</router-link>
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item px-1 ">
            <a class="nav-link fs" href="https://foodclubasia.com/pages/contact" target="_blank"
              @click="closeMenu">Contact Us</a>
          </li>

          <li class="nav-item px-1">
            <router-link class="nav-link fs" to="/" @click="closeMenu">Search</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- <div class="cart-icon-container">
    <div class="d-flex justify-content-end">
      <i class="fa fa-cart-plus" aria-hidden="true" @click="showCart = !showCart"></i>
    </div>
  </div> -->
  <router-view />

  <!-- <router-view v-if="shouldShowCartIcon">

    <div class="cart-icon-container" v-if="shouldShowCartIcon">
      <div class="d-flex justify-content-end">
        <i class="fa fa-cart-plus" aria-hidden="true" @click="showCart = !showCart"></i>
      </div>
    </div>

  </router-view> -->


</template>

<script>
export default {

  computed: {
    shouldShowNavbar() {
      const hiddenRoutes = ['Menu', 'View Menu', 'OrderConfirmation', 'Catlog', 'Catlog Category'];
      return !hiddenRoutes.includes(this.$route.name);
    },
  },

  methods: {
    closeMenu() {
      if (window.innerWidth < 992) {
        document.querySelector(".navbar-collapse").classList.remove("show");
      }
    },
  },
};
</script>

<style scoped>
.img-logo {
  width: 100px;
}

/* .router-link-active {
  font-weight: bold;
} */

.fs {
  font-family: PT Sans, sans-serif;
  font-size: 17px;
  color: black;

}

/* .text-theme {
 
} */

/* .nav-item:hover {
  background-color: rgb(238, 238, 238);
  opacity: 0.8;

} */
.dropdown-menu {
  padding: 10px;
}

.nav-link:hover {
  opacity: 0.8;
  background-color: rgb(247, 241, 241);
}

.cart-icon-container {
  position: fixed;
  bottom: 20px;
  /* Adjust this value to your liking */
  right: 20px;
  /* Adjust this value to your liking */
  z-index: 999;
  /* Ensure the icon stays on top of other elements */
}

.fa-cart-plus {
  border-radius: 50%;
  align-items: center;
  padding: 10px;
  font-size: 20px;
  margin: 5px;
  background: #cb2027;
  color: white;
}
</style>
