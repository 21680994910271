import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      title: "Home Page",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  {
    path: "/about",
    name: "About",
    component: () => import("../views/AboutView.vue"),
    meta: {
      title: "About",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  // insider directory
  {
    path: "/insider-profile-directory",
    name: "Insider profiles",
    component: () => import("../views/InsiderDirectory.vue"),
    meta: {
      title: "Insider profiles",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features,Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  // vendor directory
  {
    path: "/vendor-directory",
    name: "Vendor directory",
    component: () => import("../views/VendorDirectory.vue"),
    meta: {
      title: "Insider profiles",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features,Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  // restaurant directory
  {
    path: "/restaurant-directory",
    name: "Restaurant profiles",
    component: () => import("../views/RestaurantDirectory.vue"),
    meta: {
      title: "Restaurant profiles",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  // Restauarant Profile view
  {
    path: "/restaurant/:resturantUrl",
    name: "Restaurant information",
    component: () => import("../views/RestaurantInformation.vue"),
    meta: {
      title: "Restaurant information",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  {
    path: "/vendor/:vendorUrl",
    name: "Vendor information",
    component: () => import("../views/VendorInformation.vue"),
    meta: {
      title: "Vendor information",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  // {
  //   path: "/restaurant-information",
  //   name: "Restaurant information",
  //   component: () => import("../views/RestaurantInformation.vue"),
  // },

  // {
  //   path: "/insider/:blog/:id",
  //   name: "Insider information",
  //   component: () => import("../views/ViewInsiderProfile.vue"),
  // },

  {
    path: "/insider/:insiderBlogUrl",
    name: "Insider information",
    component: () => import("../views/ViewInsiderProfile.vue"),
    // meta: {
    //   title: "Insider information",
    //   description:
    //     "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    // },
  },

  // {
  //   path: "/view-articles/:articleId",
  //   name: "view articles",
  //   component: () => import("../views/ViewArticles.vue"),
  // },

  {
    path: "/view-articles/:titleUrl",
    name: "View articles",
    component: () => import("../views/ViewArticles.vue"),
    meta: {
      title: "View articles",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  {
    path: "/view-updates/:titleUrl",
    name: "View Updates",
    component: () => import("../views/ViewUpdates.vue"),
    meta: {
      title: "View updates",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  {
    path: "/view-sponsored-articles/:titleUrl",
    name: "View Sponsored Articles",
    component: () => import("../views/ViewSponsoredArticles.vue"),
    meta: {
      title: "View updates",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  {
    path: "/view-blogs/:blogUrl",
    name: "View blogs",
    component: () => import("../views/ViewBlog.vue"),
    meta: {
      title: "Etna Italian Restaurant-A Taste of Italy in Singapore",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  {
    path: "/insider-category/:insiderBlogUrl/:category",
    name: "Insider category",
    component: () => import("../views/ViewInsiderCategory.vue"),
  },

  {
    path: "/insider-topics/:insiderBlogUrl/:topics",
    name: "Insider topics",
    component: () => import("../views/ViewInsiderTopics.vue"),
  },
  // {
  //   path: "/view-blogs",
  //   name: "View blogs",
  //   component: () => import("../views/ViewBlog.vue"),
  // },

  // {
  //   path: "/foodclubasia-category/",
  //   name: "View category",
  //   component: () => import("../views/CategoryView.vue"),
  // },

  {
    path: "/foodclubasia-category/:category/:id",
    name: "View category",
    component: () => import("../views/CategoryView.vue"),
  },

  {
    path: "/latest-restaurant",
    name: "Restaurant",
    component: () => import("../views/ViewAllLatestRestaurant.vue"),
    meta: {
      title: "Restaurant",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  {
    path: "/featured-restaurant",
    name: "Featured restaurant",
    component: () => import("../views/ViewAllFeaturedRestaurant.vue"),
    meta: {
      title: "Featured restaurant",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  {
    path: "/featured-insider",
    name: "Insiders",
    component: () => import("../views/ViewAllFeaturedInsider.vue"),
    meta: {
      title: "Insiders",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  {
    path: "/insider-insights",
    name: "Insiders insights",
    component: () => import("../views/ViewAllInsiderInsights.vue"),
    meta: {
      title: "Insiders insights",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  {
    path: "/articles",
    name: "Articles",
    component: () => import("../views/ViewAllArticles.vue"),
    meta: {
      title: "Articles",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  {
    path: "/location",
    name: "Location",
    component: () => import("../views/ViewAllLocation.vue"),
    meta: {
      title: "Articles",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  {
    path: "/locations/:name",
    name: "Restaurant Location",
    component: () => import("../views/ViewRestaurantByLocation.vue"),
  },

  {
    path: "/insider-blogs/:insiderBlogUrl",
    name: "Insider Blogs",
    component: () => import("../views/ViewAllInsiderProfileBlog.vue"),
    meta: {
      title: "Insider",
      description:
        "Food Club Asia unveiled its Multi-Faceted Platform that combines Blog-sharing features, Restaurant and Vendor directory Listing, Profile Account Page, and Booking Platform in an easy-to-use dashboard.",
    },
  },

  {
    path: "/menu/:resturantUrl",
    name: "Menu",
    component: () => import("../views/ViewMenu.vue"),
  },

  {
    path: "/view-menu/:resturantUrl/:categoryUrl",
    name: "View Menu",
    component: () => import("../views/ViewCategoryMenu.vue"),
  },

  // {
  //   path: "/sample-menu/:resturantUrl",
  //   name: "Sample Menu",
  //   component: () => import("../views/ViewSampleMenu.vue"),
  // },

  {
    path: "/catlog/:vendorUrl",
    name: "Catlog",
    component: () => import("../views/ViewCatlogCategory.vue"),
  },

  {
    path: "/catlog-category/:vendorUrl/:categoryUrl",
    name: "Catlog Category",
    component: () => import("../views/ViewCatlog.vue"),
  },

  {
    path: "/order-confirmation/:resturantUrl",
    name: "OrderConfirmation",
    component: () => import("../views/OrderConfirmation.vue"),
  },

  {
    path: "/menu/:resturantUrl",
    name: "ViewMenuTable",
    component: () => import("../views/ViewMenuTableNumber.vue"),
  },

  // {
  //   path: "/foodclubasia-category/:category/:id",
  //   name: "View category",
  //   component: () => import("../views/CategoryView.vue"),
  // },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 };
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  document.title = to.name + " | Food Club Asia Community";

  NProgress.configure({ showSpinner: false });
  NProgress.start();

  if (window.gtag) {
    window.gtag("config", "UA-144352033-1", {
      page_path: to.path,
    });
  }
  next();
});
router.afterEach(() => {
  NProgress.done();
});

export default router;
